import React from "react"

import PortfolioItem from "./portfolioItem"
import { useStaticQuery, graphql } from "gatsby"

export default function PortfolioList() {
  const data = useStaticQuery(graphql`
    {
      bcr: file(relativePath: { eq: "bcr.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      storageWarrior: file(relativePath: { eq: "storagewarrior.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      ycdp: file(relativePath: { eq: "ycdp.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      beanworks: file(relativePath: { eq: "beanworks.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      verusArt: file(relativePath: { eq: "verusart.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      fish: file(relativePath: { eq: "fish-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      hiClouds: file(relativePath: { eq: "hi-clouds.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      tstm: file(relativePath: { eq: "tstm.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      pong: file(relativePath: { eq: "pong.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <div>
      <PortfolioItem
        title="Brush Creek Ranch"
        img={data.bcr.childImageSharp.gatsbyImageData}
        techs={["Next.js", "WordPress", "CSS Modules"]}
        description="My first build at Wallop and one of the company's first headless builds. This is a multisite and it took some time to figure out the best way to create a headless multisite. It was definitely challenging getting started but turned out great and I learned a lot in the process."
        siteLink="https://www.brushcreekranch.com"
      />

      <PortfolioItem
        title="Storage Warrior"
        img={data.storageWarrior.childImageSharp.gatsbyImageData}
        techs={["WordPress", "JavaScript", "Sass"]}
        description="I had a lot of fun building this website. It starts with WordPress Underscores starter theme, Gulp 4 as a task runner, SASS for writing clean BEM styles and Webpack to compile the JavaScript. The WordPress backend uses ACF for page templates and Gutenberg for posts and non-templated pages. I used Cloudflare for caching and SSL, and server-side caching for additional speed."
        siteLink="https://www.storagewarrior.ca"
      />

      <PortfolioItem
        title="Youth Cannabis Diversion Program"
        img={data.ycdp.childImageSharp.gatsbyImageData}
        techs={["WordPress", "JavaScript", "Sass", "Tailwindcss"]}
        description="I built this website using Roots' Sage as a starter theme. I find the theme really cleans up the theme folder and is very easy to use. The blade templating language makes templating a breeze. There are some parallax effects on the flair creates a subtle pleasing effect for the viewer. I also used Tailwindcss for the first time. It ensures the style is cohesive and you end up spending a lot less time in your CSS."
        siteLink="https://www.youthcannabisdiversionprogram.com"
      />

      <PortfolioItem
        title="Beanworks"
        img={data.beanworks.childImageSharp.gatsbyImageData}
        techs={["WordPress", "JavaScript", "Sass"]}
        description="Beanworks is a custom theme built in WordPress using Advanced Custom Fields, custom post types, custom widgets and taxonomies to create an easy-to-edit backend. I used Gulp to compile Sass and JavaScript ES6. It's a fully responsive theme using CSS Grid and Flexbox."
        siteLink="https://www.beanworks.com"
      />

      <PortfolioItem
        title="Verus Art"
        img={data.verusArt.childImageSharp.gatsbyImageData}
        techs={["Shopify", "Liquid", "Ajax"]}
        description="This an ecommerce site built with Shopify. The theme is fully responsive and customized for Verus Art so they can easily add new artists and pages without needing to dig into the code. Shopify does not make it easy to change the layout so I had to find a way to use metafields to allow the client to easily update the content while working within the design."
        siteLink="https://www.verusart.com"
      />

      <PortfolioItem
        title="Talk Social To Me"
        img={data.tstm.childImageSharp.gatsbyImageData}
        techs={["WordPress", "PHP", "Sass"]}
        description="For the frontend I used modern CSS techniques (CSS Grid, Flexbox) to create responsive pages, widgets and templates. For the backend I wanted to create a simple, clean, and intuitive layout with minimal plugins. I used custom post-types, taxonomies and widgets and tailored them with Advanced Custom Fields to make them easy for the client to edit pages and create new ones."
        siteLink="https://www.talksocialtome.com"
      />

      <PortfolioItem
        title="Animated SVG"
        img={data.fish.childImageSharp.gatsbyImageData}
        techs={["Blender", "SVG", "Freestyle"]}
        description="I've always wanted to learn 3D animation, so I decided to turn a doodle of mine into an SVG animation. The fish are originally 3D objects in Blender, I then used Freestyle SVG Exporter to create animated SVG's. It required some tweaking of the SVG code and filling in colors in Inkscape but finally I got something I was happy with."
        pageLink="/fish"
      />

      <PortfolioItem
        title="Hi Clouds"
        img={data.hiClouds.childImageSharp.gatsbyImageData}
        techs={["Vue.js", "JavaScript (ES6)"]}
        description="This project was created to pull weather data from Yr.no specifically for the use of quickly getting important cloud information for finding the northern lights. But of course it can be used for any use case when low, medium and high clouds are needed. I built this using Vue.js, vanilla JavaScript (ES6) for the API calls and Sass. The hardest part of this project was navigating an API in Norwegian."
        codeLink="https://gitlab.com/fleetfoot/auro-weather"
      />

      <PortfolioItem
        title="Hockey Pong"
        img={data.pong.childImageSharp.gatsbyImageData}
        techs={["JavaScript ES6", "SVG"]}
        description="This project was to better understand Object-Oriented Programming with JavaScript, working with some of the new features of ES6 as well as getting my hand's dirty creating SVG's with HTML. I learned basic game design concepts such as the game loop, hit-boxes and assigning controls to move rendered SVG's with the keyboard."
        // siteLink="https://fleetfoot.gitlab.io/Hockey-Pong"
        codeLink="https://gitlab.com/fleetfoot/Hockey-Pong"
      />
    </div>
  )
}

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const PortfolioItem = props => (
    <div>
        <h2 className="mb-6">{props.title}</h2>

        <div className="flex flex-col md:grid items-start gap-x-8 mb-16" style={{ gridTemplateColumns: `300px 1fr` }}>
            <div className="image-wrapper mb-8 md:mb-0" style={{ maxWidth: `300px` }}>
                <GatsbyImage
                    image={props.img}
                    alt={props.title}
                />
            </div>

            <div>
                <ul className="flex ml-0 mb-1">
                    {props.techs.map(tech => (
                        <li className="bg-lightGray mr-2 py-1 px-4 rounded-lg font-bold text-sm" key={tech}>{tech}</li>
                    ))}
                </ul>

                <p className="mb-4">{props.description}</p>

                <div>
                    {props.siteLink ? (
                        <a
                            className="font-bold pr-3"
                            href={props.siteLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Visit Site
                        </a>
                    ) : null}
                    {props.codeLink ? (
                        <a
                            className="font-bold  pr-3"
                            href={props.codeLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Source Code
                        </a>
                    ) : null}
                    {props.pageLink ? (
                        <Link
                            className="font-bold"
                            to={props.pageLink}
                        >
                            View Page
                        </Link>
                    ) : null}
                </div>
            </div>
        </div>
    </div>
);

export default PortfolioItem;

import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pageTitle"
import PortfolioList from "../components/portfolioList"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <PageTitle>Portfolio</PageTitle>
    <PortfolioList />
  </Layout>
)

export default IndexPage
